import * as copy from "copy-to-clipboard";
import {NzMessageService} from "ng-zorro-antd/message";
import {projectMessages} from "@app/shared/constants/project-messages";

const isSafari = () => {
  let isSafari = false;
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1) {
    isSafari = true;
  }

  return isSafari;
}

export const copyToClipboard = (text: string, nzMessageService: NzMessageService | null = null) => {
  const result = copy(text);

  if (result) {
    nzMessageService?.info(projectMessages.getTmUrl);
  } else {
    if (!isSafari()) {
      nzMessageService?.error(projectMessages.copyClpFailed);
    }
  }
}
