import {TagModule} from "primeng/tag";
import {Router} from "@angular/router";
import {Template} from "@app/shared/interfaces";
import {NgOptimizedImage} from '@angular/common';
import {TmButtonsComponent} from "@app/shared/ui-kits";
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import {NzDividerComponent} from "ng-zorro-antd/divider";
import {NzPopoverDirective} from "ng-zorro-antd/popover";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {cloneObject, extractColorNumber} from "@app/shared/helpers";
import {Carousel, CarouselModule, CarouselResponsiveOptions} from "primeng/carousel";
import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnDestroy, OnInit} from '@angular/core';
import {AnalyticsService, AppStateService, AuthService, TemplateService} from "@app/shared/services";
import {NzSpinComponent} from "ng-zorro-antd/spin";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'sf-resume-examples-section',
  templateUrl: './resume-examples-section.component.html',
  styleUrls: ['./resume-examples-section.component.scss'],
  imports: [
    CarouselModule,
    TagModule,
    NzDividerComponent,
    NgOptimizedImage,
    NzTooltipDirective,
    NzPopoverDirective,
    TmButtonsComponent,
    NzSpinComponent,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResumeExamplesSectionComponent implements OnInit, OnDestroy {
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  private readonly authService = inject(AuthService);
  private readonly templateService = inject(TemplateService);
  private readonly analyticsService = inject(AnalyticsService);
  protected readonly appStateService = inject(AppStateService);

  protected responsiveOptions: CarouselResponsiveOptions[] = [
    {
      breakpoint: '1600px',
      numVisible: 4,
      numScroll: 1
    },
    {
      breakpoint: '1300px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '800px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '520px',
      numVisible: 1,
      numScroll: 1
    },
  ];

  protected selectedTemplate: Template | null = null;

  ngOnInit() {
    /** This needed to enable vertical scroll on mobile */
    Carousel.prototype.onTouchMove = () => {
    };
  }

  ngOnDestroy() {
    this.selectedTemplate = null
  }

  protected getDotsNumber(carousel: Carousel) {
    return Array(this.appStateService.templatesForSlide.length - carousel.numVisible + 1);
  }

  protected onPreviewTemplate($event: Event, template: Template) {
    this.appStateService.templatePreview$.next({$event, template});
    this.analyticsService.track("Template Preview From Slider", {
      templateId: template.templateId,
      isPremiumTemplate: template.premium
    });
  }

  protected onSelectTemplate(template: Template) {
    this.selectedTemplate = template;

    if (this.authService.isAuthenticated) {
      const templateCopy = cloneObject(template);
      templateCopy.settings.settings.theme.colorId = this.appStateService.getTmColors(template)[(extractColorNumber(template.src)! - 1) || 0].id;

      this.templateService.addTemplate(templateCopy)
        .pipe(
          finalize(() => this.selectedTemplate = null),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((res) => {
          this.router.navigate(['documents', res.documentId]);
        });

      this.analyticsService.track("Template Choose From Slider", {
        templateId: templateCopy.templateId,
        isPremiumTemplate: templateCopy.premium
      });
    } else {
      this.router.navigate(['auth'], {queryParams: {returnUrl: 'cv-templates'}});
    }
  }

}

